import * as Dialog from '@radix-ui/react-dialog';
import FullWrapper from 'core/FullWrapper';
// eslint-disable-next-line import/no-extraneous-dependencies
import OfferBody from 'shared/sections/OfferBody';

import cn from './style.module.sass';

type ModalOfferProps = {
    modalOpen: boolean;
    setModalOpen: () => void;
};

export default function ModalOffer({ modalOpen, setModalOpen }: ModalOfferProps) {
    return (
        <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
            <Dialog.Portal>
                <Dialog.Content
                    style={{ overflow: 'auto' }}
                    className={cn['customModal']}
                    onTouchStart={e => e.stopPropagation()} // для safari iOS включения скролла
                    onTouchMove={e => e.stopPropagation()} // для safari iOS включения скролла
                    onWheel={e => {
                        e.stopPropagation(); // для safari iOS включения скролла
                    }}
                >
                    <div className={cn['modal-offer__cross-wrap']}>
                        <FullWrapper caseName={cn.modal_wrapper}>
                            <div className={cn['modal-offer__grid']}>
                                <Dialog.Close
                                    className={cn['modal-offer__cross']}
                                    onClick={setModalOpen}
                                >
                                    <span>Закрыть</span>
                                </Dialog.Close>
                            </div>
                        </FullWrapper>
                    </div>

                    <OfferBody
                        isEnterprise={false}
                        showCheckboxes={true}
                        defaultCheckedType=""
                        title="Оставьте заявку"
                        subtitle={`Чтобы оставить заявку, напишите нам или позвоните по&nbsp;номеру
                                <a href="tel:84955405179" onClick={PhoneMetrikaBitrix()}>
                                +7 495 540-51-79</a>, и&nbsp;мы&nbsp;сами все заполним`}
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
