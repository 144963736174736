'use client';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';

import BasicImage from 'core/BasicImage';
import FullWrapper from 'core/FullWrapper';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider, { CustomArrowProps } from 'react-slick';
import { useMedia } from 'react-use';

import { listAboutSlider } from 'shared/sections/AboutDevelopment/constants';
import cn from './style.module.sass';
import ScrollAnimation from 'core/ScrollAnimation';

const AboutDevelopment = () => {
    const isMobile = useMedia('(max-width: 1199px)');

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    let slider: unknown;

    const SlickArrowLeft = (props: CustomArrowProps) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { currentSlide, onClick } = props;

        return (
            <button
                id={cn['arrow-left']}
                disabled={currentSlide === 0}
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                onClick={onClick}
                {...props}
            >
                <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.3999 1.5L7.2499 7.35L1.3999 13.2"
                        stroke="#D4DAE5"
                        strokeWidth="2"
                    />
                </svg>
            </button>
        );
    };

    const SlickArrowRight = (props: CustomArrowProps) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { currentSlide, slideCount, onClick } = props;

        return (
            <button
                id={cn['arrow-right']}
                disabled={currentSlide === slideCount ?? 0 - 1}
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                onClick={onClick}
                {...props}
            >
                <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.3999 1.5L7.2499 7.35L1.3999 13.2"
                        stroke="#D4DAE5"
                        strokeWidth="2"
                    />
                </svg>
            </button>
        );
    };

    const settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SlickArrowRight />,
            prevArrow: <SlickArrowLeft />,
            variableWidth: !isMobile,
        }),
        [isMobile]
    );
    return (
        <ScrollAnimation>
            <FullWrapper smallPaddings={true} isMargin={false} caseName={cn['about-development']}>
                <div className={cn['about-developmentHeader']}>Клиенты о нас</div>
                <div className={cn['about-developmentContainer']}>
                    {isMount && (
                        <Slider
                            /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
                            ref={c => (slider = c)}
                            className={cn['about-developmentSlider']}
                            {...settings}
                        >
                            {listAboutSlider.map(item => {
                                return (
                                    <div key={item.title} className={cn['about-developmentItem']}>
                                        <div className={cn['about-developmentTitle']}>
                                            <span className={cn['about-developmentTitleSpan']}>
                                                «
                                            </span>
                                            {item.title}
                                            <span>»</span>
                                        </div>

                                        <div className={cn['about-developmentInner']}>
                                            <div className={cn['about-developmentInfo']}>
                                                <div className={cn['about-developmentAuthor']}>
                                                    {item.author}
                                                </div>
                                                <div className={cn['about-developmentPosition']}>
                                                    {item.position}
                                                </div>
                                            </div>
                                            <BasicImage
                                                width={148}
                                                height={57}
                                                src={item.icon}
                                                alt="icon"
                                                className={cn['about-developmentIcon']}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    )}
                </div>
            </FullWrapper>
        </ScrollAnimation>
    );
};

export default AboutDevelopment;
