import clsx from 'clsx';

import cn from './style.module.sass';

type HireTeamProps = {
    linkText: string;
    customClass?: string;
    customWrapperClass?: string;
    classNameLink?: string;
    modalOpen?: () => void;
};

export default function HireTeam({
    linkText,
    modalOpen,
    classNameLink = '',
    customClass = '',
    customWrapperClass = '',
}: HireTeamProps) {
    return (
        <div className={clsx(cn['hire-team__wrapper'], customWrapperClass)}>
            <button
                className={clsx(cn['button--mainHeader'], classNameLink, customClass)}
                onClick={modalOpen}
            >
                <span>{linkText}</span>
            </button>
        </div>
    );
}
