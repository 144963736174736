export const listAboutSlider = [
    {
        // eslint-disable-next-line max-len
        title: 'Результат работы однозначно можно назвать успешным, благодаря этому опыту есть понимание движения в улучшении продуктов Технопарка «Сколково». Сформированы проблемные места и масштабируемые направления.',
        author: 'Мария Кравченко',
        position: 'Руководитель департамента внешних коммуникаций',
        // eslint-disable-next-line sonarjs/no-duplicate-string
        icon: '/images/svg/about-development/sk-techno.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Профессионализм команды Вебпрактик позволил проекту Rukami в кратчайшие сроки организовать и провести в онлайне мероприятие с участием более 30 000 пользователей. Были воплощены в жизнь наши самые невероятные идеи по вовлечению и удержанию молодежи на портале.',
        author: 'Мария Тучина',
        position: 'Руководитель фестиваля Rukami',
        icon: '/images/svg/about-development/rukami.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Платформа для конкурса ПРО//ЧТЕНИЕ на старте требовала тщательную проработку архитектуры, а в течение самих испытаний — оперативную и качественную поддержку non-stop. Такое глубокое погружение в проект редко встретишь со стороны подрядчика. Нам повезло работать с профессионалами.',
        author: 'Максим Матросов',
        position: 'Старший руководитель проектов технологических конкурсов НТИ Up Great',
        icon: '/images/svg/about-development/upgrate.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'На всех этапах работы команда «Вебпрактик» нацелена на результат, проявляет высокий уровень профессионализма, исключительную клиентоориентированность и готовность к множественным итерациям практически в режиме 24/7. Мы партнеры уже много лет и рады дальнейшему сотрудничеству.',
        author: 'Анастасия Житенёва',
        position: 'Руководитель Департамента корпоративных коммуникаций ГАП «Ресурс»',
        icon: '/images/svg/about-development/gapresurs.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Вебпрактик» успешно создали новую версию сайта «Почта Банк». Был обновлен дизайн и разработана современная программная платформа, обеспечивающая интеграцию с внутрибанковскими и внешними системами.',
        author: 'Павел Куданкин',
        position: 'Руководитель направления веб-проектов, Почта Банк',
        icon: '/images/svg/about-development/pochtabank.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Мы довольны полученным опытом работы и понимаем, что требуются технологические решения для развития проекта. Спасибо команде Вебпрактик, профессиональный взгляд которой позволил нам найти новые точки роста',
        author: 'Мария Будкова',
        position: 'Эксперт по интернет-маркетингу РЭЦ',
        icon: '/images/svg/about-development/expocenter.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Было очень трудно найти специалистов по маркетингу, которые разбираются в нашей специфике. Команда Вебпрактик быстро погрузилась в тему венчурных инвестиций и помогла нам выйти на качественно новый уровень коммуникации с аудиторией.',
        author: 'Мария Иванова',
        position: 'Руководитель Skolkovo Adventureland',
        icon: '/images/svg/about-development/sk-techno.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Команда «Вебпрактик» помогла нам определить вектор развития SEO-направления, формализовать задачи и системно двигаться по ним. Коллеги быстро понимают и принимают в работу задачи. Однозначно, продолжим сотрудничество.',
        author: 'Михаил Чернышов',
        position: 'Руководитель проекта МТС Инвестиции',
        icon: '/images/svg/about-development/mts.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Команда агентства «Вебпрактик» всегда четко, профессионально и ответственно подходит к проектам и отдельным задачам, даже небольшим. Они всегда нацелены на результат. Спасибо за регулярные подробные отчёты с прогнозами результата.',
        author: 'Мария Захарова',
        position: 'Руководитель проекта «Дети в Сколково»',
        icon: '/images/svg/about-development/sk-techno.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Компания «Бонум» сотрудничает с агентством «Вебпрактик» с 2018 г. Это очень сильная команда, которая полностью погрузилась в специфику нашего бизнеса. У них всегда креативный подход и точная, аргументированная стратегия.',
        author: 'Надежда Михайленко',
        position: 'Руководитель отдела маркетинга ООО «БОНУМ»',
        icon: '/images/svg/about-development/bonum.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'С 2017 года «Вебпрактик» изменяет наш сайт под возникающие потребности: внедряет полезные интеграции маркетинговых и других сервисов, предлагает доработки по структуре и личному кабинету и обеспечивает его круглосуточную доступность.',
        author: 'Алексей Красносельский',
        position: 'Директор по маркетингу школы Летово',
        icon: '/images/svg/about-development/letovo.svg',
    },
    {
        // eslint-disable-next-line max-len
        title: 'Несмотря на пандемию и самоизоляцию вместе с командой «Вебпрактик» мы собрали заявок на поступление из интернета даже больше, чем ожидали. Рекомендуем агентство всем, кому важно быть эффективным при любом кризисе.',
        author: 'Маргарита Фролова',
        position: 'Менеджер по рекламе и связям с общественностью Колледжа МИД',
        icon: '/images/svg/about-development/colledz.svg',
    },
];
