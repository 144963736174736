'use client';
import { useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import HireTeam from 'shared/sections/HireTeam';
import ModalOffer from 'shared/sections/ModalOffer';

type OfferButtonProps = {
    linkText: string;
    customClass?: string;
    customWrapperClass?: string;
};

export default function OfferButton({
    linkText,
    customClass,
    customWrapperClass,
}: OfferButtonProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const onOpenModal = () => setModalOpen(true);
    const onCloseModal = () => setModalOpen(false);

    useLockBodyScroll(modalOpen);

    return (
        <>
            {modalOpen && <ModalOffer modalOpen={modalOpen} setModalOpen={onCloseModal} />}
            <HireTeam
                linkText={linkText}
                customClass={customClass}
                customWrapperClass={customWrapperClass}
                modalOpen={onOpenModal}
            />
        </>
    );
}
